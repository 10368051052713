import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import Helmet from 'react-helmet';

const seoImages = {
    openGraph: 'opengraph_2.jpg',
    twitter: 'twitter_2.jpg',
    google: 'google_2.jpg'
};

const seoImageURL = (baseUrl, file) => `${baseUrl}/images/${file}`;
const seoURL = (baseUrl, path) => baseUrl!=='' ? new URL(path, baseUrl).href : path;

const getMetaTags = ({
    title, description, url, contentType, published, updated, category, tags, twitter, googleImagePath, ogImagePath, twitterImagePath, siteContext
}) => {
    const metaTags = [
        { itemprop: 'name', content: title || siteContext.siteName },
        { itemprop: 'description', content: description || siteContext.siteDescription },
        { itemprop: 'image', content: seoImageURL(siteContext.cdnBaseUrl || siteContext.siteUrl, googleImagePath || seoImages.google) },
        { name: 'description', content: description || siteContext.siteDescription },
        { name: 'og:title', content: `${title || siteContext.siteName}` },
        { name: 'og:type', content: contentType || "website" },
        { name: 'og:url', content: url },
        { name: 'og:image', content: seoImageURL(siteContext.cdnBaseUrl || siteContext.siteUrl, ogImagePath || seoImages.openGraph) },
        { name: 'og:description', content: description || siteContext.siteDescription },
        { name: 'og:site_name', content: siteContext.siteName },
        { name: 'referrer', content: 'origin-when-crossorigin' },
        { name: 'robots', content: 'index, follow' }
    ];

    if (tags) metaTags.push({ name: 'keywords', content: tags });
    
    if (siteContext.fbAppId) metaTags.push({ name: 'fb:app_id', content: siteContext.fbAppId });
    if (siteContext.fbPages) metaTags.push({ name: 'fb:pages', content: siteContext.fbPages });
    if (siteContext.fbAdmins) metaTags.push({ name: 'fb:admins', content: siteContext.fbAdmins });

    if (siteContext.twitterSite) {
        metaTags.push({ name: 'twitter:card', content: 'summary_large_image' });
        metaTags.push({ name: 'twitter:site', content: siteContext.twitterSite });
        metaTags.push({ name: 'twitter:title', content: `${title || siteContext.siteName}` });
        metaTags.push({ name: 'twitter:description', content: description || siteContext.siteDescription });
        metaTags.push({ name: 'twitter:creator', content: twitter || siteContext.twitterSite });
        // Twitter Summary card images must be at least 120x120px
        metaTags.push({ name: 'twitter:image:src', content: seoImageURL(siteContext.cdnBaseUrl || siteContext.siteUrl, twitterImagePath || seoImages.twitter) });
    }

    if (published) metaTags.push({ name: 'article:published_time', content: published });
    if (updated) metaTags.push({ name: 'article:modified_time', content: updated });
    if (category) metaTags.push({ name: 'article:section', content: category });
    if (tags) metaTags.push({ name: 'article:tag', content: tags });

    return metaTags;
};

class SEO extends Component {

    static propTypes = {
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        path: PropTypes.string,
        contentType: PropTypes.string,
        published: PropTypes.string,
        updated: PropTypes.string,
        category: PropTypes.string,
        tags: PropTypes.string,
        twitter: PropTypes.string,
        googleImagePath: PropTypes.string,
        ogImagePath: PropTypes.string,
        twitterImagePath: PropTypes.string,
        scripts: PropTypes.array,
        siteContext: PropTypes.shape({
            siteUrl: PropTypes.string,
            cdnBaseUrl: PropTypes.string,
            siteName: PropTypes.string,
            siteDescription: PropTypes.string,
            fbAppId: PropTypes.string,
            twitterSite: PropTypes.string
        })
    };

    render() {
        const { schema, title, url } = { url: seoURL(this.props.siteContext.seoUrl, this.props.path), ...this.props };
        return (
            <Helmet
                htmlAttributes={{
                    lang: 'en',
                    itemscope: undefined,
                    itemtype: `http://schema.org/${schema || "WebPage"}`,
                }}
                // Title must be less than 155 characters
                title={title || (this.props.siteContext ? this.props.siteContext.siteName : "Patri & Gyuri Esküvő").substring(0, 155)}
                link={[
                    { rel: 'canonical', href: url },
                ]}
                meta={getMetaTags({ url: url, ...this.props })}
                script={this.props.scripts}
            />
        );
    }
}

export default SEO;