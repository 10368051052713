import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router';

import { Home } from './Home.js'
import { Response } from './Response.js'

export default class Routes extends Component {
  render(){
    return (
      <Switch>
        <Route path="/response" component={Response} />
        <Route exact path="/" component={Home} />
        <Redirect to="/" />
      </Switch>
      );
  }
}