import React from 'react';

const SiteContext = React.createContext(
    {
        siteUrl: new URL(window.location.href).origin,
        seoUrl: process.env.REACT_APP_SEO_BASE || new URL(window.location.href).origin,
        cdnBaseUrl: process.env.REACT_APP_CDN_BASE || new URL(window.location.href).origin,
        siteName: 'Patri & Gyuri EskÜvő',
        siteDescription: 'Patri & Gyuri Esküvői Meghívás és Információk',
        fbAppId: process.env.REACT_APP_FB_APP_ID,
        fbAdmins: process.env.REACT_APP_FB_ADMIN_ID,
        fbPages: process.env.REACT_APP_FB_PAGE_ID,
        gTrackingId: process.env.REACT_APP_G_TRACKING_ID
    });

export default SiteContext;