import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from 'react-scroll-up';

import Routes from "./Routes";
import SiteContext from "./SiteContext";
import SEO from "./components/SEO";
import './App.css';

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <SiteContext.Consumer>{siteContext => <SEO siteContext={siteContext} title={siteContext.siteName} description={siteContext.siteDescription} schema="WebSite" path="/" />}</SiteContext.Consumer>
          <Routes />
          <ScrollToTop showUnder={300} style={{bottom:65, right:15, zIndex:100}}>
            <span className="scrollToTheTop-icon"><FontAwesomeIcon icon={faAngleDoubleUp} /></span>
          </ScrollToTop>
        </div>
      </Router>
    );
  }
}

export default App;

