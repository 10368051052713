import React, { Component } from 'react';
import { Form, Button, Alert, Row, Col } from 'react-bootstrap'
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios'

import './MailForm.css';

export class MailForm extends Component {
    constructor(props, context) {
        super(props, context);

        this.getTargetValue = this.getTargetValue.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleComingChange = this.handleComingChange.bind(this);
        this.handleDismiss = this.handleDismiss.bind(this);
        this.handleSendMessage = this.handleSendMessage.bind(this);

        this.defaultMessage = {
            fullName: '',
            emailAddress: '',
            coming: true,
            notComing: false,
            bringingSpouse: false,
            spouseFullName: '',
            bringingKids: false,
            numKidsUnder3: 0,
            numKidsFrom3To10: 0,
            numKidsOver10: 0,
            transportationNeeded: false,
            foodAllergyOrPreference: '',
            kidsCornerNeeded: false,
            subject: 'Visszajelzés',
            message: ''
        };

        this.state = {
            message: this.defaultMessage,
            showError: false,
            showSuccess: false,
            showInProgress: false
        };
    }

    getTargetValue(target) {
        if (!target)
            return undefined;

        if (target.type === 'checkbox') {
            return target.checked;
        } else {
            return target.value;
        }
    }

    handleChange(e) {
        this.setState({
            message: {
                ...this.state.message,
                [e.target.id]: this.getTargetValue(e.target)
            }
        });
    }

    handleComingChange(e) {
        this.setState({
            message: {
                ...this.state.message,
                coming: this.getTargetValue(e.target) === "coming"
            }
        });
    }

    handleDismiss() {
        this.setState({ showError: false, showSuccess: false, showInProgress: false });
    }

    handleSendMessage(e) {
        e.preventDefault();

        this.setState({ showError: false, showInProgress: true, showSuccess: false });

        axios.post('/api/message', this.state.message).then(
            result => {
                this.setState({
                    message: this.state.message,
                    showError: false,
                    showInProgress: false,
                    showSuccess: true
                });
            }
        ).catch(
            error => {
                this.setState({ showError: true, showInProgress: false, showSuccess: false });
            }
        );
    }

    render() {
        return (
            <div>
                <Form onSubmit={this.handleSendMessage} >
                    <Form.Group controlId="fullName">
                        <Form.Label>Név:</Form.Label>
                        <Form.Control type="text" value={this.state.message.fullName} onChange={this.handleChange} placeholder="Teljes név" required />
                        <Form.Control.Feedback />
                    </Form.Group>
                    <Form.Group controlId="emailAddress">
                        <Form.Label>Email cím:</Form.Label>
                        <Form.Control type="email" value={this.state.message.emailAddress} onChange={this.handleChange} placeholder="Email cím" required />
                        <Form.Control.Feedback />
                        <Form.Text className="text-muted">
                            Ide fogjuk megerősíteni, ha megkaptuk a visszajelzésed.
                        </Form.Text>
                    </Form.Group>
                    <fieldset key="coming-radio">
                        <Form.Group as={Row}>
                            <Form.Label as="legend" className="pt-0" column sm={3}>
                                Ott leszek/leszünk:
                        </Form.Label>
                            <Col sm={2}>
                                <Form.Check type="radio" label="Igen" value="coming" checked={this.state.message.coming} onChange={this.handleComingChange} />
                            </Col>
                            <Col sm={2}>
                                <Form.Check type="radio" label="Nem" value="notComing" checked={!this.state.message.coming} onChange={this.handleComingChange} />
                            </Col>
                            <Col></Col>
                        </Form.Group>
                    </fieldset>
                    {/* <Form.Group controlId="coming">
                        <Form.Check type="checkbox" label="Ott leszek/leszünk az esküvőn" checked={this.state.message.coming} onChange={this.handleChange} />
                        <Form.Control.Feedback />
                    </Form.Group> */}
                    {this.state.message.coming &&
                        <Form.Group controlId="bringingSpouse">
                            <Form.Check type="checkbox" label="Párommal jövök" checked={this.state.message.bringingSpouse} onChange={this.handleChange} />
                            <Form.Control.Feedback />
                        </Form.Group>
                    }
                    {this.state.message.coming && this.state.message.bringingSpouse &&
                        <Form.Group controlId="spouseFullName">
                            <Form.Label>Párod neve:</Form.Label>
                            <Form.Control type="text" value={this.state.message.spouseFullName} onChange={this.handleChange} placeholder="Teljes név" required={this.state.message.bringingSpouse} />
                            <Form.Control.Feedback />
                        </Form.Group>
                    }
                    {this.state.message.coming &&
                        <Form.Group controlId="bringingKids">
                            <Form.Check type="checkbox" label="Hozom a gyerekeket" checked={this.state.message.bringingKids} onChange={this.handleChange} />
                            <Form.Control.Feedback />
                        </Form.Group>
                    }
                    {this.state.message.coming && this.state.message.bringingKids &&
                        <Form.Row>
                            <Form.Group as={Col} controlId="numKidsUnder3">
                                <Form.Label>0-2 éves korig</Form.Label>
                                <Form.Control as="select" onChange={this.handleChange}>
                                    <option>0</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} controlId="numKidsFrom3To10">
                                <Form.Label>3-10 éves korig</Form.Label>
                                <Form.Control as="select" onChange={this.handleChange}>
                                    <option>0</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} controlId="numKidsOver10">
                                <Form.Label>11 éves kortól</Form.Label>
                                <Form.Control as="select" onChange={this.handleChange}>
                                    <option>0</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                    }
                    {this.state.message.coming &&
                        <div>
                            {/* <Form.Group controlId="transportationNeeded">
                                <Form.Check type="checkbox" label="Szeretnék kisbuszos transzfert" checked={this.state.message.transportationNeeded} onChange={this.handleChange} />
                                <Form.Control.Feedback />
                                <Form.Text className="text-muted">
                                    Széll Kálmán térről indulna illetve érkezne adott időpontokban.
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="kidsCornerNeeded">
                                <Form.Check type="checkbox" label="Szeretnék gyereksarkot" checked={this.state.message.kidsCornerNeeded} onChange={this.handleChange} />
                                <Form.Control.Feedback />
                                <Form.Text className="text-muted">
                                    Elegendő igény esetén a 3-10 éveseknek tervezünk felügyeletet szervezni.
                                </Form.Text>
                            </Form.Group> */}
                            <Form.Group controlId="foodAllergyOrPreference">
                                <Form.Label>Étel allergiák illetve preferenciák:</Form.Label>
                                <Form.Control type="text" value={this.state.message.foodAllergyOrPreference} onChange={this.handleChange} placeholder="Nincs" />
                                <Form.Control.Feedback />
                            </Form.Group>
                        </div>
                    }
                    <Form.Group controlId="message">
                        <Form.Label>Egyéb üzenet:</Form.Label>
                        <Form.Control as="textarea" rows={6} value={this.state.message.message} onChange={this.handleChange} placeholder="További információk, kérdések és kérések" />
                        <Form.Control.Feedback />
                    </Form.Group>
                    {
                        this.state.showError &&
                        <Alert variant="danger" onDismiss={this.handleDismiss}>
                            <h4>Sikertelen visszajelzés!</h4>
                            <p>
                                Nem sikerült elküldeni a visszajelzést. Talán nem jó az internetkapcsolatod. Próbáld újra!
                            </p>
                        </Alert>
                    }
                    {
                        this.state.showSuccess &&
                        <Alert variant="success" onDismiss={this.handleDismiss}>
                            <h4>Sikeresen visszajeleztél!</h4>
                            <p>
                                Hamarosan levelet fogsz kapni megerősítésként. Ha nem kapsz levelet egy órán belül, kérlek ismételd meg a visszajelzést. (Előtte érdemes lehet megnézni a spamek közt.)
                            </p>
                        </Alert>
                    }
                    {
                        this.state.showInProgress &&
                        <Alert variant="warning" onDismiss={this.handleDismiss}>
                            <h4>Visszajelzés küldése...</h4>
                        </Alert>
                    }
                    <Form.Group>
                        <Button type="submit" variant="success" size="lg" disabled={!this.state.message.fullName || !this.state.message.emailAddress || this.state.showInProgress} default><FontAwesomeIcon icon={faEnvelope} /> Küldés</Button>
                        <Button variant="primary" size="lg" className="float-right" onClick={() => { this.props.history.push('/') }}>Vissza</Button>
                    </Form.Group>
                </Form>
            </div>
        );
    }
}