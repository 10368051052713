import React, { Component } from 'react';
import { Carousel, Row, Col, Image, Container, Button, Table } from 'react-bootstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faHandshake, faRing, faCameraRetro, faCocktail, faUtensils, faBirthdayCake, faFemale, faMusic, faCar, faBus, faCloudMeatball } from '@fortawesome/free-solid-svg-icons';
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { SliderRail, Handle, Track, Tick } from "./components/sliderComponents";
import { startOfMonth, endOfDay, startOfToday, format } from "date-fns";
import { dateLocale } from "date-fns/locale/hu"
import { scaleTime } from "d3-scale";

import SiteContext from "./SiteContext";
import SEO from "./components/SEO";
import './Home.css';

import notSimplyImg from './images/memes/2zakce.jpg';
import braceYourselvesImg from './images/memes/2zah63.jpg';
import wouldBeGreatImg from './images/memes/2zaja4.jpg';
import burningHouseImg from './images/memes/2zagzm.jpg';
import noDanceImg from './images/memes/2zaie2.jpg';
import shortCeremonyImg from './images/memes/3084ga.jpg';
import moneyOnlyImg from './images/memes/2zahte.jpg';
import noTimeImg from './images/memes/2zal54.jpg';
import decorationImg from './images/memes/2zalj8.jpg';
import quickMenuImg from './images/memes/2zamcj.jpg';

import patri1Img from './images/fun/patri1.jpg';
import patri2Img from './images/fun/patri2.jpg';
import patri3Img from './images/fun/patri3.jpg';
import patri4Img from './images/fun/patri4.jpg';
import patri5Img from './images/fun/patri5.jpg';

import gyuri1Img from './images/fun/gyuri1.jpg';
import gyuri2Img from './images/fun/gyuri2.jpg';
import gyuri3Img from './images/fun/gyuri3.jpg';
import gyuri4Img from './images/fun/gyuri4.jpg';
import gyuri5Img from './images/fun/gyuri5.jpg';

import frameImage from './images/frame.png';

function formatTick(ms) {
    return format(new Date(ms), "MMM D", { locale: dateLocale });
}

const oneDayInterval = 1000 * 60 * 60 * 24;

export class Home extends Component {

    constructor() {
        super();

        const today = startOfToday();
        const startOfSlider = startOfMonth(new Date(2019, 4, 1));
        const endOfSlider = endOfDay(new Date(2019, 8, 20));

        this.state = {
            selected: today,
            min: startOfSlider,
            max: endOfSlider,
            updated: today
        };

        this.onUpdate = this.onUpdate.bind(this);
        this.onChange = this.onChange.bind(this);
        this.renderImages = this.renderImages.bind(this);
        this.renderFrame = this.renderFrame.bind(this);
        this.renderInvitation = this.renderInvitation.bind(this);
        this.renderLocation = this.renderLocation.bind(this);
        this.renderProgramme = this.renderProgramme.bind(this);
        this.renderFun = this.renderFun.bind(this);
        this.renderInfo = this.renderInfo.bind(this);
        this.renderGallery = this.renderGallery.bind(this);
    }

    onUpdate([ms]) {
        this.setState({
            updated: new Date(ms)
        });
    }

    onChange([ms]) {
        this.setState({
            selected: new Date(ms)
        });
    };

    renderImages() {
        const { min, max, updated } = this.state;

        const percentage = Math.min((updated - min) * 100 / (max - min), 83);

        var activeImageIndex = 0;

        switch (updated.getMonth()) {
            case 5: activeImageIndex = 1; break;
            case 6: activeImageIndex = 2; break;
            case 7: activeImageIndex = 3; break;
            case 8: activeImageIndex = 4; break;
            case 9: activeImageIndex = 5; break;
            default: activeImageIndex = 0; break;
        }

        return (
            <div>
                <div style={{
                    left: `${percentage}%`,
                    float: 'left',
                    position: "relative",
                    transform: "translate(-50%, -22%)",
                    zIndex: 2,
                    width: '17%'
                }}>
                    <Carousel className="carousel-fade fun" interval={null} indicators={false} controls={false} keyboard="false" activeIndex={activeImageIndex} onSelect={() => { }} >
                        <Carousel.Item>
                            <Image src={patri1Img} alt="Patri 1" fluid rounded />
                        </Carousel.Item>
                        <Carousel.Item>
                            <Image src={patri2Img} alt="Patri 2" fluid rounded />
                        </Carousel.Item>
                        <Carousel.Item>
                            <Image src={patri3Img} alt="Patri 3" fluid rounded />
                        </Carousel.Item>
                        <Carousel.Item>
                            <Image src={patri4Img} alt="Patri 4" fluid rounded />
                        </Carousel.Item>
                        <Carousel.Item>
                            <Image src={patri5Img} alt="Patri 5" fluid rounded />
                        </Carousel.Item>
                    </Carousel>
                </div>

                <div style={{
                    position: "relative",
                    float: 'right',
                    transform: "translate(50%, -22%)",
                    zIndex: 2,
                    width: '17%'
                }}>
                    <Carousel className="carousel-fade fun" interval={null} indicators={false} controls={false} keyboard="false" activeIndex={activeImageIndex} onSelect={() => { }} >
                        <Carousel.Item>
                            <Image src={gyuri1Img} alt="Gyuri 1" fluid rounded />
                        </Carousel.Item>
                        <Carousel.Item>
                            <Image src={gyuri2Img} alt="Gyuri 2" fluid rounded />
                        </Carousel.Item>
                        <Carousel.Item>
                            <Image src={gyuri3Img} alt="Gyuri 3" fluid rounded />
                        </Carousel.Item>
                        <Carousel.Item>
                            <Image src={gyuri4Img} alt="Gyuri 4" fluid rounded />
                        </Carousel.Item>
                        <Carousel.Item>
                            <Image src={gyuri5Img} alt="Gyuri 5" fluid rounded />
                        </Carousel.Item>
                    </Carousel>
                </div>
            </div>
        );
    }

    renderFun() {
        const { min, max, selected } = this.state;

        const dateTicks = scaleTime()
            .domain([min.getTime(), max.getTime()])
            .ticks(4)
            .map(d => +d).concat([max.getTime()]);

        return (
            <div className="container-fluid">
                <div style={{ marginTop: "6rem", marginBottom: "6rem", width: "100%", height: "auto" }}>
                    {this.renderImages()}
                    <Slider
                        mode={1}
                        step={oneDayInterval}
                        domain={[+min, +max]}
                        rootStyle={{
                            position: "relative",
                            width: "100%",
                            clear: "both"
                        }}
                        onUpdate={this.onUpdate}
                        onChange={this.onChange}
                        values={[+selected]}
                    >
                        <Rail>
                            {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
                        </Rail>
                        <Handles>
                            {({ handles, getHandleProps }) => (
                                <div>
                                    {handles.map(handle => (
                                        <Handle
                                            key={handle.id}
                                            handle={handle}
                                            domain={[+min, +max]}
                                            getHandleProps={getHandleProps}
                                        />
                                    ))}
                                </div>
                            )}
                        </Handles>
                        <Tracks right={false}>
                            {({ tracks, getTrackProps }) => (
                                <div>
                                    {tracks.map(({ id, source, target }) => (
                                        <Track
                                            key={id}
                                            source={source}
                                            target={target}
                                            getTrackProps={getTrackProps}
                                        />
                                    ))}
                                </div>
                            )}
                        </Tracks>
                        <Ticks values={dateTicks}>
                            {({ ticks }) => (
                                <div>
                                    {ticks.map(tick => (
                                        <Tick
                                            key={tick.id}
                                            tick={tick}
                                            count={ticks.length}
                                            format={formatTick}
                                        />
                                    ))}
                                </div>
                            )}
                        </Ticks>
                    </Slider>
                </div>
            </div>);
    }

    renderFrame() {
        return (
            <div className="light-container centering-container">
                <Image src={frameImage} fluid />
                <div className="centered framed-text">
                    Patri & Gyuri
                    <div><small>2019.09.20.</small></div>
                </div>
            </div>
        );
    }

    renderInvitation() {
        return (
            <div className="dark-container">
                <h1 className="text-center">Meghívó</h1>
                <p>
                    Örömmel tudatjuk, hogy a próbaidő sikeresen lezárult és a további együttműködésünk eredményeképpen esetleg megszülető utódok és egyéb javak jogi helyzetének tiszta mederbe terelése érdekében a házasság mellett döntöttünk.
                </p>
                <p>
                    Szeretettel meghívunk a 2019. szeptember 20-án 17 órától tartandó esküvőnkre és az azt követő vacsorára és bulira. Kérjük mihamarabb jelezz vissza az alábbi gombra kattintva! Az esetleges változásokat tudod jelezni a későbbiekben is a visszajelzés megismétlésével.
                </p>
                <div className="spaced centering-container" style={{ height: 40 }}>
                    <div className="centered">
                        <Button variant="success" size="lg" onClick={() => { this.props.history.push('/response') }}>Visszajelzek</Button>
                    </div>
                </div>
            </div>
        );
    }

    renderProgramme() {
        return (
            <div className="dark-container">
                <h2 className="text-center">Program</h2>
                <Table responsive>
                <tbody>
                        <tr>
                            <td>16:30-</td>
                            <td><FontAwesomeIcon className="mr-2" icon={faHandshake} size="lg" /> Fogadás</td>
                            <td>Érkezni szabad korábban is.</td>
                        </tr>
                        <tr>
                            <td>17:30</td>
                            <td><FontAwesomeIcon className="mr-2" icon={faRing} size="lg" /> Ceremónia</td>
                            <td>Az étterem teraszán.</td>
                        </tr>
                        <tr>
                            <td>18:00</td>
                            <td><FontAwesomeIcon className="mr-2" icon={faCameraRetro} size="lg" /> Csoportképek</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>18:30-</td>
                            <td><FontAwesomeIcon className="mr-2" icon={faCocktail} size="lg" /> Frissítők és játékok</td>
                            <td>Nyitott bár lesz az esküvő teljes időtartamára.</td>
                        </tr>
                        <tr>
                            <td>20:00-</td>
                            <td><FontAwesomeIcon className="mr-2" icon={faUtensils} size="lg" /> Vacsora</td>
                            <td>Svédasztalos vacsora lesz vegetáriánus és tejmentes opciókkal.<br />Kérjük a visszajelzésnél említsd meg, ha van ételallergiád.</td>
                        </tr>
                        <tr>
                            <td>21:15-</td>
                            <td><FontAwesomeIcon className="mr-2" icon={faMusic} size="lg" /> Nyitó tánc</td>
                            <td>Buli kisebb megszakításokkal kb. 4:00-ig</td>
                        </tr>
                        <tr>
                            <td>22:30</td>
                            <td><FontAwesomeIcon className="mr-2" icon={faBirthdayCake} size="lg" /> Torta</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>23:00</td>
                            <td><FontAwesomeIcon className="mr-2" icon={faFemale} size="lg" /> Menyecske tánc</td>
                            <td>Ha szeretnél ajándékot adni, kérjük borítékos formában tedd, <br /> mert mást nem nagyon tudunk magunkkal vinni külföldi otthonunkba.</td>
                        </tr>
                        <tr>
                            <td>24:00</td>
                            <td><FontAwesomeIcon className="mr-2" icon={faCloudMeatball} size="lg" /> Töltött-káposzta</td>
                            <td>Mert nincs esküvő káposztaszag nélkül.</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        );
    }

    renderLocation() {
        return (
            <div className="light-container">
                <h2 className="text-center">Helyszín</h2>
                <p>
                    Az eseményt a Budakeszi Vadasparkban található <a href="http://dudok.hu/" hrefLang="en" target="_blank" rel="external noopener noreferrer">Dudok Rendezvényházban</a> tartjuk (a Pilisi Parkerdő épületével szemben).
                </p>
                <div className="location-map">
                    <iframe title="Dudok Rendezvényház" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2694.232817166217!2d18.889382651776348!3d47.52433017907706!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476a75be281f38b5%3A0x9fb181c254b618c2!2zRHVkb2sgUmVuZGV6dsOpbnlow6F6IMOpcyBWZW5kw6lnbMWR!5e0!3m2!1shu!2suk!4v1557153480797!5m2!1shu!2suk" width="100%" height="500" frameBorder="0" style={{ border: 0 }} allowFullScreen></iframe>
                </div>
                <h3 className="text-center">Megközelítés</h3>
                <Row>
                    <Col className="text-center"><FontAwesomeIcon className="mb-4" icon={faBus} size="3x" /></Col>
                    <Col className="text-center"><FontAwesomeIcon className="mb-4" icon={faCar} size="3x" /></Col>
                </Row>
                <Row>
                    <Col><p>Tömegközlekedéssel a Széna térről induló 795-ös busszal érdemes jönni, amely 15 percenként jár és közvetlen a Telki úti bejáratnál tesz le (Budakeszi erdő- és vadgazdaság megálló). Hazafelé a vacsora utántól kisbusz fog indulni a <b>Széll Kálmán térre</b> egészen zárásig.</p></Col>
                    <Col><p>A Google navigáció félrevezető. Autóval Budakeszi felől érdemes ezen az útvonalon jönni: Budakeszi Fő út > Temető utca > Telki út, majd a Telki úton 2,5 km után első lehetőségnél balra kell fordulni.</p><p>Parkolás a Pilisi Parkerdő épülete mellett, illetve a Vadaspark Telki út felőli bejárata után, 100m-re az épülettől lehetséges.</p></Col>
                </Row>
            </div>
        );
    }


    renderGallery() {
        return (
            <div className="dark-container">
                <h2 className="text-center">
                    Képek
                </h2>
                <p>Az esküvőnek létrehozunk egy FB csoportot, ahova majd lehet képeket feltölteni illetve kérdéseket feltenni. Itt lesz a link, illetve a legjobb képeket ide is kitesszük majd.</p>
                <Carousel className="carousel-fade meme-carousel mt-4 mb-4" indicators={false}>
                    <Carousel.Item>
                        <Image src={notSimplyImg} alt="Patriciának nem veszi fel csak úgy a gyűrűt" fluid />
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image src={braceYourselvesImg} alt="Szeptember 20-án esküvő" fluid />
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image src={wouldBeGreatImg} alt="Jó lenne ha mindenki ott lenne 17-re" fluid />
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image src={burningHouseImg} alt="És visszajeleznétek időben" fluid />
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image src={moneyOnlyImg} alt="Küldföldön élünk, nem tudunk mit kezdeni tárgyi ajándékkal" fluid />
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image src={noDanceImg} alt="Nem lesz első tánc, de lesz ceremónia" fluid />
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image src={shortCeremonyImg} alt="De csak egy rövid" fluid />
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image src={decorationImg} alt="Meg sok dekoráció" fluid />
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image src={quickMenuImg} alt="Gyorsan összerakott menüsor" fluid />
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image src={noTimeImg} alt="Mert nincs időnk esküvőt szervezni" fluid />
                    </Carousel.Item>
                </Carousel>
            </div>
        );
    }

    renderInfo() {
        return (
            <div>
                <h2 className="text-center">
                    Állapotjelző
                </h2>
                {this.renderFun()}
                <div className="text-center mb-4"><small>Húzd el a csúszkát ;)</small><small className="d-block d-sm-none">Mobil esetén fordítsd el 90 fokkal a kijelzőt.</small></div>
            </div>
        );
    }

    render() {

        return (
            <div>
                <SiteContext.Consumer>{siteContext => <SEO siteContext={siteContext} path={this.props.location.pathname} title="Meghívó Patri és Gyuri esküvőjére - 2019.09.20." description="Meghívó Patri és Gyuri 2019.09.20.-ikán tartandó esküvőjére a budakeszi Dudok Rendezvényhézba"
                    tags="esküvő,meghívó,Gyuri,Patri,Dudok Rendezvényház" />}</SiteContext.Consumer>
                <Container>
                    {this.renderFrame()}
                    {this.renderInvitation()}
                    {this.renderInfo()}
                    {this.renderProgramme()}
                    {this.renderLocation()}
                    {this.renderGallery()}
                </Container>
            </div >);
    }
}