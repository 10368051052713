import React, { Component } from 'react';
import { Container} from 'react-bootstrap';

import SiteContext from "./SiteContext";
import SEO from "./components/SEO";
import { MailForm } from './components/MailForm';
import './Response.css';

export class Response extends Component {
    render() {
        return (
            <Container>
                <SiteContext.Consumer>{siteContext => <SEO siteContext={siteContext} schema="ContactPage" path={this.props.location.pathname} title="Visszajelzés" description="Visszajelzés az esküvőn való részvételről"  tags="visszajelzés" />}</SiteContext.Consumer>
                <div className="light-container">
                    <h2 className="text-center">
                        Visszajelzés a részvételről
                    </h2>
                    <MailForm history={this.props.history} />
                </div>
            </Container>
        );
    }
}